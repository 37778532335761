import React, { useState, useEffect } from "react";
import "./documentCategory.css";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, InputAdornment } from "@mui/material";
import { CircularProgress, Snackbar } from "@mui/material";
import Chip from "@mui/material/Chip";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  CreateDocType,
  DeleteDocType,
} from "../../ApiHelper/categoryAndType.js";
import { getAllDocumentType } from "../AdminConsoleApiHelper/AdminApiHelper.js";
import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import DialogContentText from '@mui/material/DialogContentText';

// import Snackbar from "@mui/material/Snackbar";

// import Modal from '@mui/material/Modal';
import {
  Select,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

import Box from "@mui/material/Box";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export default function DocumentTypeView({ currCatName, currCatId }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [buttonLoading, setLoadingButton] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleOpenDelete = () => setOpenDeleteDialog(true);
  const handleCloseDelete = () => setOpenDeleteDialog(false);
  const [photo, setphoto] = useState();
  const [name, setname] = useState("");
  const [desc, setDesc] = useState("");
  const [instructions, setInstructions] = useState("");
  const [checkedEnglish, setCheckedEnglish] = React.useState(true);
  const [checkedArabic, setCheckedArabic] = React.useState(true);
  const [checkedAzerbaijani, setCheckedAzerbaijani] = React.useState(true);

  const [langauges,setLanguages]=useState("english,arabic");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [loadingType, setLoadingType] = useState(false);
  const [allDocTypeData, setAllDocTypeData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [tempInputValue, setTempInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [tempTags, setTempTags] = useState([]);
  const [deleteDocTypeId,setDeleteDocTypeId]=useState("")
  const [allowCreate,setAllowCreate]=useState("no")
  const handleSnakebarClick = () => {
    setSnackBarOpen(true);
  };

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      // Add the tag to the list
      setTags((prevTags) => [...prevTags, inputValue.trim()]);
      // Clear the input value
      setInputValue("");
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleDeleteTag = (tagToDelete) => {
    // Remove the tag from the list
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };
  const handleSnakebarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const action = (
    <div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnakebarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  const handleDocTypeClick = (
    selectedName,
    selectedValue,
    selectedSecondDropdownId,
    selectedSecondDropdownValue
  ) => {
    // console.log(selectedSecondDropdownId);
    // setOpenModal(false);
    console.log(
      selectedName,
      selectedValue,
      selectedSecondDropdownId,
      selectedSecondDropdownValue
    );
    // console.log(selectedSecondDropdownId);
    navigate(`/admin-console/managedoc-entity`, {
      state: {
        selectedName,
        selectedValue,
        selectedSecondDropdownId,
        selectedSecondDropdownValue,
      },
    });
  };
  const GetDocEntity = async (selectedOptionId) => {
    try {
      // setCurrPage("doctype");
      setLoadingType(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await getAllDocumentType(
        user_id,
        userorg_id,
        selectedOptionId,
        userToken
      );
      if (res.code === "200") {
        console.log(res.data);
        setAllDocTypeData(res.data);
        setAllowCreate(res.allow_create)
        setLoadingType(false);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingType(false);
    }
  };
  const handlelanguage=()=>{
    console.log(checkedArabic,checkedEnglish)
    if(checkedEnglish==true && checkedArabic==true){
       return "english,arabic"
     }
     if(checkedEnglish==true && checkedAzerbaijani==true){
      return "english,azerbaijani(latin)"
    }
     else if(checkedArabic==true && checkedAzerbaijani==false){
      return "arabic,azerbaijani(latin)"
     }
     else if(checkedArabic==true){
      return "arabic"
     }
     else if(checkedAzerbaijani==true){
      return "azerbaijani(latin)"
     }
     else if(checkedEnglish==true){
      return "english"
     }
     else{
      return ""
     }
  }

  const handleSave = async (cat_id) => {
    // try {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;
    // console.log(name,desc,checkedEnglish,checkedArabic,tags)
    // if(checkedArabic==true && checkedArabic==true && checkedAzerbaijani==true){
    //   setResponseMsg("Please select at most two languages");
    //   handleSnakebarClick();
    //   return ;
    // }
    // await setTimeout(handlelanguage, 1000);

    const res = await CreateDocType(
      userToken,
      user_id,
      org_id,
      cat_id,
      name,
      photo,
      desc,
      tags.join(", "),
    );
    if (res.code === "200") {
      setOpen(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
      GetDocEntity(currCatId);
    } else {
      setOpen(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    }
    // GetCatType();
    console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };

  const handleDelete = async (cat_id, document_type_id) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;

    const res = await DeleteDocType(
      userToken,
      user_id,
      org_id,
      cat_id,
      document_type_id
    );
    if (res.code === "200") {
      setOpenDeleteDialog(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
      GetDocEntity(currCatId);
      // GetCatType();
    } else {
      setOpenDeleteDialog(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    }
    console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };
  useEffect(() => {
    GetDocEntity(currCatId);
  }, []);
  return (
    <>
      {allowCreate==="yes" &&
      <div className="doc_category_card" onClick={handleOpen}>
        <div className="doc_category_card_icon">
          <img src="/plus_logo.png" width={50} height={50} />
          <p>Create New Doc Type</p>
        </div>
      </div>
        }
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "100%",
            display: "flex",

            alignItems: "flex-end",
            flexDirection: "column",
          },
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-betwwen",
              alignItems: "flex-end",
              marginBottom: "30px",
            }}
          >
            <Typography variant="h6">Create new document type</Typography>
            <Box position="absolute" left="90%">
              <IconButton>
                <CloseIcon onClick={handleClose} />
              </IconButton>
            </Box>
          </Box>
          {/* Your form for creating a new user goes here */}
          <Box display="flex" flexGrow={1} style={{ flexDirection: "column" }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                fontWeight="bold"
                sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
              >
                Name
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box sx={{ flex: 4, marginLeft: "1%" }}>
                <TextField
                  label="Name"
                  sx={{ width: "400px", marginRight: "15px" }}
                  // value={documentName}
                  onChange={(e) => setname(e.target.value)}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                fontWeight="bold"
                sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
              >
                Desc
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box sx={{ flex: 4, marginLeft: "2%" ,marginTop:"10px"}}>
                <TextField
                  label="Description"
                  sx={{ width: "400px", marginRight: "15px" }}
                  // value={documentName}
                  onChange={(e) => setDesc(e.target.value)}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                fontWeight="bold"
                sx={{ flex: 1, marginRight: "18px", marginTop: "15px" }}
              >
                Instructions
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box sx={{ flex: 4, marginLeft: "2%" ,marginTop:"10px"}}>
                <TextField
                  label="Instructions"
                  sx={{ width: "400px", marginRight: "0px" }}
                  // value={documentName}
                  onChange={(e) => setInstructions(e.target.value)}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              <Box
                fontWeight="bold"
                sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
              >
                Keywords
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  // label="Enter Alias Name"

                  placeholder="Enter Alias Name"
                  variant="outlined"
                  sx={{ width: "400px",marginRight:"15px" }}
                  margin="normal"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  helperText="Please hit enter to add the keyword after typing"
                  InputProps={{
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                    },
                    startAdornment: Array.isArray(tags)
                      ? tags.map((tag) => (
                          <Chip
                            color="primary"
                            key={tag}
                            label={tag}
                            onDelete={() => handleDeleteTag(tag)}
                            style={{ marginTop: "10px" }}
                          />
                        ))
                      : null,
                  }}
                />
              </Box>

            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              {/* <Box
                fontWeight="bold"
                sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
              >
                Select Languages
                
                <span style={{ color: "red" }}>*</span>
              </Box> */}
              {/* <Box>
              <FormGroup style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
              <FormControlLabel control={<Checkbox   checked={checkedEnglish}
                onChange={async (e)=>{
                await setCheckedEnglish(e.target.checked)
              }}
               defaultChecked />} label="English" />
              <FormControlLabel control={<Checkbox   checked={checkedArabic}
              onChange={async (e)=>{
               await setCheckedArabic(e.target.checked)
               
              }}
               defaultChecked />} label="Arabic" />
               <FormControlLabel control={<Checkbox   checked={checkedAzerbaijani}
              onChange={async (e)=>{
               await setCheckedAzerbaijani(e.target.checked)
               
              }}
               defaultChecked />} label="azerbaijani(latin)" />
            </FormGroup>
              </Box> */}
              

            </Box>
            <div className="image_div">
              <span>Add Image:</span>
              <input
              style={{marginLeft:"20px"}}
                type="file"
                id="file-input"
                onChange={(e) => {
                  setphoto(e.target.files[0]);
                }}
              />
            </div>
          </Box>

          <DialogActions
            sx={{
              marginBottom: "10px",
              marginTop: "15px",
              marginRight: "0",
              gap: "14px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "none", fontWeight: "bold" }}
              onClick={() => {
                handleSave(currCatId);
              }}
              backgroundColor={buttonLoading && "#616161"}
              disabled={buttonLoading}
            >
              {buttonLoading && <CircularProgress size={16} />}Save
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {allDocTypeData.map((docTypeData, index) => (
        <div className="doc_category_card"
        onClick={() => {
          handleDocTypeClick(
            currCatName,
            currCatId,
            docTypeData.id,
            docTypeData.name
          );
        }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="doc_category_card_icon"
             
            >
              {docTypeData.icon !== "" && (
                <img
                  src={docTypeData.icon}
                  style={{ width: "50px", height: "50px" }}
                />
              )}
              {docTypeData.icon === "" && (
                <ReceiptIcon style={{ width: "50px", height: "50px" }} />
              )}
            </div>
            {docTypeData.allow_edit==="yes" &&
            
            <Tooltip
              title="Delete"
              onClick={(e) => {
                e.stopPropagation();
                setDeleteDocTypeId(docTypeData.id)
                handleOpenDelete();
              }}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            }
          </div>
          <p>{docTypeData.name}</p>
        </div>
      ))}
       <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Disagree</Button>
          <Button onClick={()=>{
          handleDelete(currCatId,deleteDocTypeId)}} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <div className="snackbardiv">
        <Snackbar
          open={snackBarOpen}
          autoHideDuration={3000}
          onClose={handleSnakebarClose}
          message={responseMsg}
          action={action}
        />
      </div>
    </>
  );
}

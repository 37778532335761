import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  HashRouter,
} from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard/Dashboard.jsx";
import Repository from "./Pages/Repository/Repository.jsx";
import RepositoryConnected from "./Pages/Repository/RepositoryConnected.jsx";
import DefaultRepository from "./Pages/Repository/DefaultRepository.jsx";

import ChangePassword from "./Pages/ChangePassword/ChangePassword.jsx";
import Favourite from "./Pages/Favourite/Favourite.jsx";
import AdminUser from "./Pages/AdminConsole/Users/User.jsx";
import SearchInvoices from "./Pages/SearchInvoice/SearchInvoice.jsx";
import DocumentType from "./Pages/AdminConsole/DocType/DocumentType.jsx";
import DocumentEntity from "./Pages/AdminConsole/DocumentEntity/DocumentEntity.jsx";
import ManageDocEntity from "./Pages/AdminConsole/DocumentEntity/ManageDocEntity.jsx";
import InvoiceExtraction from "./Pages/AdminConsole/InvoiceExtraction/InvoiceExtraction.jsx";
import OnedriveAccount from "./Pages/Repository/OnedriveAccount.jsx";
import SearchDocType from "./Pages/AdminConsole/DocType/SearchDocType.jsx";
import { AppProvider } from "./Pages/Includes/AppContext";
import { PathProvider } from "./Pages/Includes/PathContext";
// import DocumentCategory from "./Pages/AdminConsole/DocumentCategory/documentCategory.jsx";
import DocumentCategoryView from "./Pages/AdminConsole/DocumentCategory/documentCategoryView.jsx";
import AdminDepartment from "./Pages/AdminConsole/Department/Department.jsx";
import ForgetPassword from "./Pages/PasswordReset/forgetPassword.jsx";
import SetNewPassword from "./Pages/PasswordReset/setNewPassword.jsx";
const ASSET_PATH = process.env.PUBLIC_URL;

function App() {
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem("userdata");
    return storedData ? JSON.parse(storedData) : null;
  });

  const checkUserData = () => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      return <Navigate to="/" />;
    }
    setUserData(JSON.parse(storedData));
    return null;
  };
  const checkUserPrivilage = () => {
    return <Navigate to="/" />
    
  };
  window.constants = {
    api_url: "https://qbnbpxhes9.execute-api.us-east-1.amazonaws.com", //live
    // api_url: " http://127.0.0.1:8000",  //local
    asset_path: ASSET_PATH, // Add the asset path to constants
    // base_path: "http://localhost:3001/documentai-zeonai/",  //local
    base_path: "https://www.documentai.zeonai.com/", //live

  };

  useEffect(() => {
    const storedData = localStorage.getItem("userdata");
    if (!storedData) {
      // User is not logged in, no need to redirect
      return;
    }

    setUserData(JSON.parse(storedData));

    // If the user is logged in and tries to access the login page, redirect to the dashboard
    // if (window.location.pathname === '/' && window.location.search !== '?logout=true') {
    //   window.location.replace('/query');
    // }
  }, []);
  const PrivateRoute = ({ element }) => {
    return userData ? element : checkUserData();
  };
  const UserPrivilangeRouteRoute = ({ element }) => {
    return (localStorage.getItem('userdata') &&  (JSON.parse(localStorage.getItem('userdata')).user_priviledge===0 || JSON.parse(localStorage.getItem('userdata')).user_priviledge===1)) ? element : checkUserPrivilage();
  };
  const SuperAdminPrivilangeRouteRoute = ({ element }) => {
    return localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" ? element : checkUserPrivilage();
  };


  return (
    <div className="App">
      <AppProvider>
        <Router>
          <PathProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route path="/setnew-password" element={<SetNewPassword />} />

              <Route
                path="/dashboard"
                element={<PrivateRoute element={<Dashboard />} />}
              />
              <Route
                path="/repository"
                element={<PrivateRoute element={<Repository />} />}
              />
              <Route
                path="/repositoryconnected"
                element={<PrivateRoute element={<RepositoryConnected />} />}
              />
              <Route
                path="/onedrive-account"
                element={<PrivateRoute element={<OnedriveAccount />} />}
              />
              <Route
                path="/change-password"
                element={<PrivateRoute element={<ChangePassword />} />}
              />
              <Route
                path="/searchinvoices"
                element={<PrivateRoute element={<SearchInvoices />} />}
              />
              <Route
                path="/admin-console/user"
                element={<UserPrivilangeRouteRoute element={<PrivateRoute element={<AdminUser />} />}/>}
              />
               <Route
                path="/admin-console/department"
                element={<SuperAdminPrivilangeRouteRoute element={ <PrivateRoute element={<AdminDepartment />} />}/>}
              />
              <Route
                path="/admin-console/document-type"
                element={<PrivateRoute element={<DocumentType />} />}
              />
              <Route
                path="/admin-console/searchdocument-type"
                element={<PrivateRoute element={<SearchDocType />} />}
              />
              <Route
                path="/admin-console/document-category"
                element={<PrivateRoute element={<DocumentCategoryView />} />}
              />
              <Route
                path="/admin-console/managedoc-entity"
                element={<PrivateRoute element={<ManageDocEntity />} />}
              />
              <Route path="/admin-console/managedoc-entity" />{" "}
              <Route
                path="/admin-console/managedoc-entity"
                element={<PrivateRoute element={<ManageDocEntity />} />}
              />
              <Route
                path="/admin-console/invoice-extraction"
                element={<PrivateRoute element={<InvoiceExtraction />} />}
              />
              <Route
                path="default-repository"
                element={<PrivateRoute element={<DefaultRepository />} />}
              />
              <Route
                path="/admin-console/managedoc-entity/:selectedValue?"
                element={<PrivateRoute element={<ManageDocEntity />} />}
              />
              <Route
                path="/favourite-list"
                element={<PrivateRoute element={<Favourite />} />}
              />
            </Routes>
          </PathProvider>
        </Router>
      </AppProvider>
    </div>
  );
}
export default App;
